import React from "react";
import Footer from "../components/footer"
import Header from "../components/header"
import SEO from "../components/seo"

const Terms = () => {
  return (
      <>
        <SEO title="Terms &amp; Conditions" />
        <Header></Header> 
        <main>
            <div className="u-grid o-text">
                <h1>Terms &amp; Conditions</h1>
                <h3>Download Rights</h3>
                <p>The content of this Website is provided for your information and to facilitate your commercial relationships with us. References to "Website" and "Content" are to this Website and the contents of this Website. You acquire no rights or licences in or to the Website and/or the Content other than the limited right to use the Website in accordance with these terms and to download on the terms set out in this section. Other than as set out in this section you may not copy, reproduce, recompile, decompile, disassemble, reverse engineer, distribute, publish, sell, display, perform, modify, upload to create derivative works from, transmit or in any other way exploit any part of the Website or the Contents.</p><p>Downloading is permitted by us provided only that it is to a single personal computer and:</p><p>Downloading is permitted by us provided only that it is to a single personal computer and: you make only personal, non-commercial, use of such download and/or printed copy; and you retain on such download and/or printed copy all copyright notices and remain bound by the terms of such wording and notices. Additionally you may not offer for sale or sell or distribute over any other medium (including distribution by over-the-air television or radio broadcast or distribution on a computer network) the Content or any part of the Content.</p><p>You may not use the Website or the Contents to construct a database of any kind nor may you store the Website (in its entirety or in any part) in databases for access by you or any third party or distribute any database websites containing all or part of the Website.</p><p>If you want to obtain our permission to use any of the Content other than as described in these terms then please contact <a href="mailto:hello@squareowl.co.uk">hello@Squareowl.co.uk</a></p>
                <h3>Intellectual property</h3>
                <p>This Website including the Content is protected by copyright and/or other proprietary rights. The Content may include content owned and controlled by third parties and licensed to the Company. All individual articles, reports and other elements making up this Website may be copyright works. You agree to abide by all additional copyright notices or restrictions contained on this Website.</p><p>You have no ownership rights or licences in any of our trading names, trademarks or in the Content except for the right to use this Website and to download the Content in accordance with these terms.</p><p>You agree to email us at hello@Squareowl.co.uk as soon as you become aware of any unauthorised use of this Website by anybody, or of any claim that this Website or any of the Contents infringe any copyright or other rights of any other party.</p>
                <h2>Cookies</h2>
                <p>A cookie is a small file of letters and numbers that we place on your computer, our website uses up to 5 different cookies. These cookies allow us to distinguish you from other users of our website, which helps us to provide you with a better experience when you browse our site.</p><p>The cookies we use are session cookies and analytical cookies.</p>
                <h3>Session Cookies</h3>
                <p>These cookies allow us to ensure that our site functions correctly and operates efficiently when you use it. Session cookies expire once you have closed our website and are not stored once your visit has ended, they are generally not considered to be invasive of your privacy.</p>
                <h3>Analytical Cookies</h3>
                <p>This type of cookie allows us to recognise and count the number of visitors to our site and to see how visitors move around our website when they are using it. This helps us to improve the way our website works, for example by ensuring that users are finding what they are looking for easily.</p>
                <h2>Privacy Policy</h2>
                <p>All reference to ‘Square Owl’, ‘Square Owl Ltd.’ herein refers to all subsidiaries and affiliates of Square Owl Ltd, Square Owl and Square Owl Ltd are used interchangeably to refer to all companies within the Square Owl group.</p><p>This online privacy policy applies only to information collected through all of Square Owl websites, software, and mobile applications, and not to information collected offline. By using our sites, software, or mobile applications you acknowledge that any information provided by you is provided voluntarily and subject to Square Owl privacy policy, to which you consent. Note that Square Owl websites, software, and mobile applications are not intended for use by a person in any state or country where such use would be contrary to local laws or regulations. Square Owl privacy policy is subject to change without notice. When changes are made, we will revise the “modified” date at the top of this policy. Square Owl encourages you to visit this page periodically so that you are aware of the most recent privacy terms. This privacy policy reflects Square Owl current business practices.</p>
                <h3>Information collection and us</h3>
                <p>When communicating with us through our websites, software, or mobile applications for these purposes you may be asked to enter your name, e-mail address, mailing address, phone number, or professional and educational experience.</p><p>Any of the information we collect from you may be used in one of the following ways:</p><ul><li>To improve our website based on the information and feedback we receive from you</li><li>To respond to your customer service requests and support needs</li><li>To evaluate and respond to your job application</li></ul><p>You acknowledge and agree that any information you provide to us through our websites, software, or mobile applications is provided voluntarily. You further agree to defend, indemnify and hold Square Owl harmless from any and all liability, claims, actions, damages, fines, penalties or judgments (including attorney's fees) that may arise from your use of Square Owl websites, software, or mobile applications.</p>
                <h3>Information sharing and disclosure</h3>
                <p>We do not sell, rent, trade, or otherwise transfer to outside parties your personally identifiable information. This does not include trusted parties who assist us in operating our website, conducting our business, or responding to your requests for service. We may provide information to trusted parties, such as our affiliates and business partners, in compliance with our privacy policy and confidential and security requirements. We may also release your information when we believe release is appropriate to comply with the law, enforce our site policies, or protect ours or others' rights, property, or safety.</p>
                <h3>Accessing and updating your personal information</h3>
                <p>You may update the personal information you provide Square Owl as required through our online services (where applicable) by logging into your account and requesting changes.</p><p>In accordance with the Data Protection Act 1998 you have the right to access any information that we hold relating to you. Please note that we reserve the right to charge a fee of £10 to cover costs incurred by us in providing you with the information.</p>
                <h2>Disclaimers</h2>
                <h3>Square Owl Content</h3>
                <p>The content on Square Owl websites, including Newsflash articles and other content set forth in the sites, is compiled from a number of public sources that, to the best of Square Owl knowledge, is true and correct. It is our intent to present only accurate information. In the event any information contained herein is erroneous, however, Square Owl Ltd (and other parties thereof) accepts no liability or responsibility. Accordingly, all information provided on this site, is provided "as-is." The information contained on Square Owl websites is provided for informational purposes only and should not be construed as legal advice.</p>
                <h3>Third party links</h3>
                <p>Links to other websites are provided for informational purposes only. While we believe the information to be accurate, we are not making any representations or warranties, nor do we accept liability or responsibility, as to the accuracy of the information contained on them.</p>
                <h3>Copyright</h3>
                <p>This website is owned by Square Owl and contains material protected by copyright laws which may not be reproduced in any form without advanced written consent of an authorised officer of Square Owl.</p>
                <h3>Contacting us</h3>
                <p>Please do not hesitate to contact us regarding any matter relating to this Privacy Policy via email at <a href="mailto:hello@squareowl.co.uk">hello@squareowl.co.uk</a> </p>
            </div>
        </main>
        <div className="u-bg-black">
            <Footer></Footer>
        </div>
    </>
    
  );
}

export default Terms;