import React from "react"
import { Link } from "gatsby"
import Instagram from "../components/instagram"
import Linkedin from "../components/linkedin"

const Footer = () => {
  return (
    <footer className="c-footer">
      <div
        itemScope
        itemType="http://schema.org/Organization"
        className="c-footer__company"
      >
        <div>
          <a href="mailto:hello@squareowl.co.uk" itemProp="email">
            hello@squareowl.co.uk
          </a>
        </div>
        <div>
          <span itemProp="telephone">0161 883 7010</span>
        </div>
        <div
          itemProp="address"
          itemScope
          itemType="http://schema.org/PostalAddress"
        >
          <span itemProp="streetAddress">
            Lancaster Buildings, 77 Deansgate
          </span>
          , <span itemProp="addressLocality">Manchester</span>.{" "}
          <span itemProp="postalCode">M3 2BW</span>
        </div>
        <p className="c-footer__copy">
          &copy; 2023 <span itemProp="legalName">Square Owl</span>. Registered
          in England, No. 8844016. VAT No.{" "}
          <span itemProp="vatID">178 8743 45</span>.
        </p>
      </div>
      <div className="c-footer__links">
        <div>
          <Link to="/terms">Terms Privacy &amp; Cookies </Link>
          <ul className="c-social">
            <li>
              <a
                href="https://www.instagram.com/square.owl/"
                aria-label="Visit Square Owl Instagram"
              >
                <Instagram />
              </a>
            </li>
            <li>
              <a
                href="https://www.linkedin.com/company/square-owl/"
                aria-label="Visit Square Owl Linkedin"
              >
                <Linkedin />
              </a>
            </li>
          </ul>
        </div>
      </div>
    </footer>
  )
}

export default Footer
