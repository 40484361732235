import React from "react";

const Instagram = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x="0"
      y="0"
      enableBackground="new 0 0 119.9 120"
      version="1.1"
      viewBox="0 0 119.9 120"
      xmlSpace="preserve"
      title="Instagram"
    >
      <path d="M84.2 0H35.8C16.1 0 0 16.1 0 35.8v48.5C0 104 16 120 35.7 120h48.5c19.7 0 35.7-16 35.7-35.7V35.8C120 16.1 103.9 0 84.2 0zm23.7 84.2c0 13.1-10.6 23.7-23.7 23.7H35.8c-13.1 0-23.7-10.6-23.7-23.7V35.8c0-13.1 10.6-23.7 23.7-23.7h48.5c13.1 0 23.7 10.6 23.7 23.7v48.4h-.1z"></path>
      <path d="M84.2 0H35.8C16.1 0 0 16.1 0 35.8v48.5C0 104 16 120 35.7 120h48.5c19.7 0 35.7-16 35.7-35.7V35.8C120 16.1 103.9 0 84.2 0zm23.7 84.2c0 13.1-10.6 23.7-23.7 23.7H35.8c-13.1 0-23.7-10.6-23.7-23.7V35.8c0-13.1 10.6-23.7 23.7-23.7h48.5c13.1 0 23.7 10.6 23.7 23.7v48.4h-.1z"></path>
      <path d="M60 29c-17.1 0-31 13.9-31 31s13.9 31 31 31 31-13.9 31-31-13.9-31-31-31zm0 50c-10.5 0-19-8.5-19-19s8.5-19 19-19 19 8.5 19 19-8.5 19-19 19z"></path>
      <path d="M60 29c-17.1 0-31 13.9-31 31s13.9 31 31 31 31-13.9 31-31-13.9-31-31-31zm0 50c-10.5 0-19-8.5-19-19s8.5-19 19-19 19 8.5 19 19-8.5 19-19 19z"></path>
      <g>
        <path d="M98.5 29.2c0 4.1-3.3 7.4-7.4 7.4-4.1 0-7.4-3.3-7.4-7.4s3.3-7.4 7.4-7.4c4.1 0 7.4 3.3 7.4 7.4z"></path>
      </g>
    </svg>
  );
}

export default Instagram;
